.my-upload-box{

}
.my-upload-box .dropzone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 4px;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border .24s ease-in-out;
    cursor: pointer;
}
.my-upload-box .dropzone:focus {
    border-color: #2196f3;
}

.my-upload-box .thumbsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 16px;
}
.my-upload-box .thumb {
    display: inline-flex;
    border-radius: 2px;
    border: 1px solid #eaeaea;
    margin-bottom: 8px;
    margin-right: 8px;
    width: 100px;
    height: 100px;
    padding: 4px;
    box-sizing: border-box;
}
.my-upload-box .thumbInner {
    display: flex;
    min-width: 0;
    overflow: hidden;
}
.my-upload-box .img {
    display: block;
    width: auto;
    height: 100%;
}

.my-upload-box.single .thumbsContainer {
    display: block;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 16px;
    width: 100%;
}
.my-upload-box.single .thumb {
    display: block;
    border-radius: 2px;
    border: 1px solid #eaeaea;
    margin-bottom: 0;
    margin-right: 0;
    width: 100%;
    height: auto;
    padding: 4px;
    box-sizing: border-box;
}
.my-upload-box.single .thumbInner {
    display: block;
    min-width: 0;
    overflow: hidden;
}
.my-upload-box.single .img {
    display: block;
    width: 100%;
    height: auto;
}
.file-upload-group.is-invalid .my-upload-box .dropzone{
    border: 1px dashed #dc3545;
}
.file-upload-group.is-invalid .my-upload-box .dropzone .upload-placeholder{
    color: #dc3545;
}